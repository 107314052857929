import { PropsWithChildren } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { PrivateRoutes } from "../types";
import LandingPage from "../modules/landing";
import FAQs from "../modules/undergraduate/faq";
import CheckStatusLanding from "../modules/undergraduate/admissions/check-admission-status";
import UGLogin from "../modules/undergraduate/onboarding/login";
import UGForgotPassword from "../modules/undergraduate/onboarding/reset-password";
import UGUpdatePassword from "../modules/undergraduate/onboarding/reset-password/update-password";

import UGradDashboardWrap from "../modules/undergraduate/student-dashboard/";
import UGradFacultyFeeWrap from "../modules/undergraduate/student-payments/faculty-fee";
import UGradSchoolFeeWrap from "../modules/undergraduate/student-payments/school-fee";
import UGradEntrePreneurFeeWrap from "../modules/undergraduate/student-payments/entrepreneur-fee";
import UGradSugFeeWrap from "../modules/undergraduate/student-payments/sug-fee";
import UGradGstFeeWrap from "../modules/undergraduate/student-payments/gst-fee";
import UGradNanissFeeWrap from "../modules/undergraduate/student-payments/naniss";
import UGradCourseRegWrap from "../modules/undergraduate/course-registration";
import StudentBiodataWrap from "../modules/undergraduate/student-details";
import StudentBiodataEditWrap from "../modules/undergraduate/student-details/edit";
import UpdateStudentPassword from "../modules/undergraduate/change-password";
import Edocumentation from "../modules/undergraduate/e-Documentation";

const ProtectedUGradRoute = ({
  isAllowed,
  redirectPath = "/undergraduate/login",
  profile,
  route,
  children,
}: PrivateRoutes) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);

  // if (profile?.request_data?.response?.profile?.hasChangedDefaultPassword == false && route !== "/undergraduate/update-password") {
  if (
    loggedAccountInfo?.profile &&
    loggedAccountInfo?.profile?.hasChangedDefaultPassword == false &&
    route !== "/undergraduate/update-password"
  ) {
    return <Navigate to="/undergraduate/update-password" replace />;
  }

  return children ? children : <Outlet />;
};
type OwnProps = ReturnType<typeof mapStateToProps>;
const AppRoutes = (pageProps: PropsWithChildren<OwnProps>) => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/faq" element={<FAQs />} />
      <Route path="/admissions/check-status" element={<CheckStatusLanding />} />
      <Route path="/undergraduate/login" element={<UGLogin />} />
      <Route path="/undergraduate/accounts/forgot-password" element={<UGForgotPassword />} />
      <Route path="/accounts/reset-password/:resetCode" element={<UGUpdatePassword />} />
      
      <Route
        path="/undergraduate/dashboard"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/dashboard"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UGradDashboardWrap />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/payments/faculty-fee"
        element={
          <ProtectedUGradRoute
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UGradFacultyFeeWrap />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/payments/naniss-fee"
        element={
          <ProtectedUGradRoute
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UGradNanissFeeWrap />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/payments/school-fee"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/payments/school-fee"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UGradSchoolFeeWrap />
          </ProtectedUGradRoute>
        }
      />

      <Route
        path="/undergraduate/payments/entrepreneur-fee"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/payments/entrepreneur-fee"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UGradEntrePreneurFeeWrap />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/payments/sug-fee"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/payments/sug-fee"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UGradSugFeeWrap />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/payments/gst-fee"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/payments/gst-fee"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UGradGstFeeWrap />
          </ProtectedUGradRoute>
        }
      />

      <Route
        path="/undergraduate/course-reg"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/course-reg"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UGradCourseRegWrap />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/personal-data"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/personal-data"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <StudentBiodataWrap />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/personal-data/update"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/personal-data/update"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <StudentBiodataEditWrap />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/update-password"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/update-password"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <UpdateStudentPassword />
          </ProtectedUGradRoute>
        }
      />
      <Route
        path="/undergraduate/e-documentation"
        element={
          <ProtectedUGradRoute
            profile={pageProps?.uGradLoginRequest}
            route="/undergraduate/e-documentation"
            isAllowed={pageProps?.uGradLoginRequest?.loggedIn}
          >
            <Edocumentation />
          </ProtectedUGradRoute>
        }
      />
    </Routes>
  );
};
const mapStateToProps = (state: any) => ({
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});
export default connect(mapStateToProps)(AppRoutes);
