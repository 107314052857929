import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UndergraduateAdmissionStatusChecker } from "../../../../../shared/templates/admissions/undergraduate/index";
import { allowNumbersOnly } from "../../../../../shared/utils";
import { StepGuide } from "./step-guide";
import { AlertMsg } from "../../../../../shared/components/alert-msg";
import { uGradAdmissions } from "../../../../../redux/action/admissions/undergraduate";
import { appConstantsUGradAdmisssions } from "../../../../../redux/action-constants/admissions/undergraduate";
import { DownloadModal } from "./download-modal";
const printMySchFeeInvoice = async ({ pageProps, payload }: any) => {
  await pageProps.printMySchoolFeeInvoice(payload);
};

const PrintSchoolFeeInvoice = ({ pageProps }: any) => {
  const [isDwModalShown, setShowDwModal] = useState<boolean>(false);
  let requestInfo = pageProps?.uGradPrintMySchoolFeeInvoiceRequest;
  let admissionData = pageProps?.admissionData;

  useEffect(() => {
    printMySchFeeInvoice({
      pageProps,
      payload: "CLEAR",
    });
  }, []);
  let checkValidationSchema = Yup.object().shape({
    email: admissionData?.schoolFeeInvoiceEmail
      ? Yup.string().trim()
      : Yup.string()
          .trim()
          .email("Valid email is required")
          .required("Required"),
    registrationNumber: admissionData?.registrationNumber
      ? Yup.string()
      : Yup.string().required("Required"),
    phoneNumber: admissionData?.schoolFeeInvoicePhoneNumber
      ? Yup.string()
      : Yup.string().matches(/^\+?[0-9]{7,20}$/, 'Phone number must be 8 digits or more').required('Phone number is required'),
  });
  let initialValues: any = {
    email: admissionData?.schoolFeeInvoiceEmail || "",
    phoneNumber: admissionData?.schoolFeeInvoicePhoneNumber || "",
    registrationNumber: admissionData?.registrationNumber || "",
    studentId: admissionData?.studentId,
    fullName: admissionData?.fullName || "",
  };
  return (
    <div className="status_check_wrap">
      {isDwModalShown && (
        <DownloadModal
          downloadLink={requestInfo?.request_data?.response?.downloadUrl}
          isDwModalShown={isDwModalShown}
          setShowDwModal={setShowDwModal}
        />
      )}
      <div className="page_heading">Admission Notification/Fees Schedule</div>
      <StepGuide step={3} />
      <div className="other_info_wrap">
        <div className="provide_details ">
          <div className="with_form">
            {requestInfo.request_status !==
              appConstantsUGradAdmisssions?.GET_SCHOOL_FEE_INVOICE_SUCCESS && (
              <Formik
                initialValues={initialValues}
                validationSchema={checkValidationSchema}
                onSubmit={async (values) => {
                  let { registrationNumber, phoneNumber, email, studentId } =
                    values;
                  let payload: any = {
                    registrationNumber,
                    phoneNumber,
                    email,
                    studentId,
                  };

                  await printMySchFeeInvoice({ pageProps, payload });
                }}
              >
                {(props: FormikProps<any>) => {
                  const {
                    values,
                    touched,
                    errors,
                    // handleBlur,
                    handleChange,
                    // isSubmitting,
                  } = props;

                  return (
                    <Form>
                      <div className="form-group">
                        <label className="label">Full Name </label>
                        <div
                          className={
                            errors.fullName &&
                            touched.fullName
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={values.fullName}
                            id="fullName"
                            name="fullName"
                            type="text"
                            disabled
                            onChange={() => {}}
                            placeholder=""
                            className={`input-wrap ${
                              errors.fullName &&
                              touched.fullName
                                ? "is-invalid-input "
                                : !errors.fullName &&
                                  values.fullName !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="fullName"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">JAMB (UTME/DE) REG No </label>
                        <div
                          className={
                            errors.registrationNumber &&
                            touched.registrationNumber
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={values.registrationNumber}
                            id="registrationNumber"
                            name="registrationNumber"
                            type="text"
                            disabled
                            onChange={() => {}}
                            placeholder="Enter your JAMB (UTME/DE) Registration No here"
                            className={`input-wrap ${
                              errors.registrationNumber &&
                              touched.registrationNumber
                                ? "is-invalid-input "
                                : !errors.registrationNumber &&
                                  values.registrationNumber !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="registrationNumber"
                          className="form-input-error"
                          component="div"
                        />
                      </div>

                      <div className="form-group">
                        <label className="label">Phone Number : </label>
                        <div
                          className={
                            errors.phoneNumber && touched.phoneNumber
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                          
                           value={allowNumbersOnly(values.phoneNumber)}
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text"
                            disabled={
                              admissionData?.schoolFeeInvoicePhoneNumber !==
                              null
                            }
                            onChange={
                              admissionData?.schoolFeeInvoicePhoneNumber !==
                              null
                                ? () => {}
                                : handleChange("phoneNumber")
                            }
                            placeholder="Enter your Phone Number"
                            className={`input-wrap ${
                              errors.phoneNumber && touched.phoneNumber
                                ? "is-invalid-input "
                                : !errors.phoneNumber &&
                                  values.phoneNumber !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>

                        <ErrorMessage
                          name="phoneNumber"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">Email Address : </label>
                        <div
                          className={
                            errors.email && touched.email
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={values.email}
                            id="email"
                            name="email"
                            type="text"
                            disabled={
                              admissionData?.schoolFeeInvoiceEmail !== null
                            }
                            onChange={
                              admissionData?.schoolFeeInvoiceEmail !== null
                                ? () => {}
                                : handleChange("email")
                            }
                            placeholder="Enter your Email Address"
                            className={`input-wrap ${
                              errors.email && touched.email
                                ? "is-invalid-input "
                                : !errors.email && values.email !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      {requestInfo.request_status ===
                        appConstantsUGradAdmisssions?.GET_SCHOOL_FEE_INVOICE_FAILURE && (
                        <AlertMsg
                          type="error"
                          message={requestInfo?.request_data.error}
                        />
                      )}
                      <div className="other_notice">
                        <div className="heading-info">
                          Password notification
                        </div>
                        <div className="other_notice_txt">
                          Please note that the phone displayed{" "}
                          <span>{values?.phoneNumber}</span> is your login
                          password after paying your school fees. Please be
                          informed that you will be unable to login to the
                          students portal without this.
                        </div>
                      </div>
                      {admissionData?.hasGeneratedSchoolFeeInvoice && (
                        <button
                          disabled={requestInfo?.is_request_processing}
                          className="btn"
                        >
                          {" "}
                          {requestInfo?.is_request_processing
                            ? "Please wait..."
                            : "Re-Print School Fee Schedule"}{" "}
                        </button>
                      )}

                      {!admissionData?.hasGeneratedSchoolFeeInvoice && (
                        <button
                          disabled={requestInfo?.is_request_processing}
                          className="btn"
                        >
                          {" "}
                          {requestInfo?.is_request_processing
                            ? "Please wait..."
                            : "Print School Fee Schedule"}{" "}
                        </button>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            )}
            {requestInfo.request_status ===
              appConstantsUGradAdmisssions?.GET_SCHOOL_FEE_INVOICE_SUCCESS &&
              requestInfo?.request_data?.response?.downloadUrl && (
                <AlertMsg
                  type="success"
                  message="Kindly print your school fees invoice and proceed to any bank to make payment. After completing your payment reload this page and provide your UTME number to get your provisional admission letter"
                />
              )}
            {requestInfo.request_status ===
              appConstantsUGradAdmisssions?.GET_SCHOOL_FEE_INVOICE_SUCCESS &&
              requestInfo?.request_data?.response?.downloadUrl && (
                <div className="alert-action">
                  Click here to{" "}
                  <span onClick={() => setShowDwModal(true)}>
                    download your school fees invoice
                  </span>
                </div>
              )}

            <div className="nav_content">
              <div className="nav_back">
                <span
                  onClick={() => {
                    pageProps?.setShowSchoolFeeInvoice(false);
                    pageProps?.setShowAcceptance(true);
                  }}
                >
                  {" "}
                  Back
                </span>
              </div>
              {admissionData?.hasPaidSchoolFee && (
                <div className="nav_back">
                  <span
                    onClick={() => {
                      pageProps?.setShowSchoolFeeInvoice(false);
                      pageProps?.setShowDownloadAdmissionLetter(true);
                    }}
                  >
                    {" "}
                    Proceed
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintSchoolFeeInvoiceWrap = (pageProps: any, admissionData: any) => {
  return (
    <UndergraduateAdmissionStatusChecker
      childComponent={<PrintSchoolFeeInvoice pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  printMySchoolFeeInvoice: uGradAdmissions.PrintMySchoolFeeInvoice,
};
const mapStateToProps = (state: any) => ({
  uGradPrintMySchoolFeeInvoiceRequest:
    state.allDdmissionsReducers.uGradPrintMySchoolFeeInvoice,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintSchoolFeeInvoiceWrap);
