import { ApiService } from "../../../../services/apiService";
import { routes } from "../../../../services/paths";
import { appConstantsUGradAdmisssions } from "../../../action-constants/admissions/undergraduate";

import { Dispatch } from 'redux';


import {
    handleRequestErrors,
} from "../../../../shared/utils";


const CheckMyAdmissionStatus = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_CHECK_ADMISSION_STATUS}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if(!response?.data?.isAdmitted){
                    //     dispatch(failure(response?.data?.notApprovedReason))
                    // }else{
                        dispatch(success(response.data))
                    // }
                   
                })
                .catch((error: any) => {
                    if (error?.response?.status === 404) {
                        dispatch(failure("We are Sorry! You were not offered admission"));
                    } else {
                        dispatch(failure(handleRequestErrors(error)));
                    }
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_RESET, clear_data: "" };
    }
}

const PrintMyAcceptanceInvoice = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_PRINT_ACCEPTANCE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    if (response?.data?.downloadUrl) {
                        
                        dispatch(success(response.data))
                    } else {
                        dispatch(failure("Something went wrong. Please try again"));
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_RESET, clear_data: "" };
    }
}

const PrintMySchoolFeeInvoice = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_PRINT_SCHOOL_FEE_INVOICE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    if (response?.data?.downloadUrl) {
                       
                        dispatch(success(response.data))
                    } else {
                        dispatch(failure("Something went wrong. Please try again"));
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_RESET, clear_data: "" };
    }
}

const PrintMyAdmissionLetter = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_PRINT_ADMISSION_LETTER}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                   
                    if (response?.data?.downloadUrl) {
                       
                        dispatch(success(response.data))
                    } else {
                        dispatch(failure("Something went wrong. Please try again"));
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_RESET, clear_data: "" };
    }
}


export const uGradAdmissions = {
    CheckMyAdmissionStatus,
    PrintMyAcceptanceInvoice,
    PrintMySchoolFeeInvoice,
    PrintMyAdmissionLetter
}