import { connect } from 'react-redux';
import { useState, useEffect } from 'react';

import { uGradAdmissions } from "../../../../../redux/action/admissions/undergraduate"
import { appConstantsUGradAdmisssions } from "../../../../../redux/action-constants/admissions/undergraduate"


import { UndergraduateAdmissionStatusChecker } from "../../../../../shared/templates/admissions/undergraduate/index";
import { StepGuide } from "./step-guide"
import { AlertMsg } from "../../../../../shared/components/alert-msg"

const getAdmissionStatus = async ({ pageProps, payload }: any) => {

    await pageProps.getMyAdmissionStatus(payload);
}


const CheckCurrentStatus = ({ pageProps }: any) => {
    const [utmeNumber, setUtmeNumber] = useState<string>("")
    let checkMyStatus = pageProps?.uGradCheckMyStatusRequest
    return (
        <div className="status_check_wrap">
            <div className="page_heading">Check Admission Status (UTME/DE)</div>
            <StepGuide step={0} />
            <div className="other_info_wrap">
                <div className="provide_details">
                    <div className="details_heading">ENTER YOUR JAMB REGISTRATION NUMBER TO CHECK YOUR ADMISSION STATUS *</div>
                    <div>
                        <input value={utmeNumber}
                            
                            id="utmeNumber"
                            name="utmeNumber"
                            onChange={(e: any) => {
                                // if (e.target.value.trim().length >= 1) {
                                setUtmeNumber(e.target.value.trim())
                                // }
                            }}
                            onKeyDown={(e: any) => {
                                let keyCode = e.code || e.key;
                                if (keyCode == "Enter") {

                                    getAdmissionStatus({
                                        pageProps,
                                        payload: {
                                            registrationNumber: utmeNumber
                                        }
                                    })
                                }
                            }}
                            type="text" placeholder="Enter your JAMB (UTME/DE) Registration No" />
                    </div>

                    <div className="check_adm_error">
                        {checkMyStatus.request_status === appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_FAILURE &&
                            <AlertMsg type="error" message={checkMyStatus?.request_data.error} />
                        }
                    </div>

                    <button
                        onClick={() => {
                            getAdmissionStatus({
                                pageProps,
                                payload: {
                                    registrationNumber: utmeNumber
                                }
                            })
                        }}
                        disabled={utmeNumber.length < 4 || checkMyStatus?.is_request_processing}
                        className="btn">{checkMyStatus?.is_request_processing ? "Checking..." : " Check Admission Status"} </button>
                </div>
                <div className="all_steps_info">
                    <div className="heading-txt">Admission Status Checking Guidelines/Instructions:</div>
                    <ul>
                        <li>Make sure you have your correct JAMB (UTME/DE) Registration No.</li>
                        <li>Enter your JAMB (UTME/DE) Registration No. in the field provided above</li>
                        <li>Click on the button title Check Admission Status</li>
                        <li>A message would be displayed on the page indicating the status of your request and further instructions.</li>
                        <li>For further enquiry, please use the contact form on the "Contact page" or send an email to info@ibbu.edu.ng</li>
                    </ul>
                </div>
                <div className="steps_help_info">
                </div>
            </div>
        </div>
    )
}

const CheckCurrentStatusWrap = (pageProps: any) => {
    return (
        <UndergraduateAdmissionStatusChecker childComponent={<CheckCurrentStatus pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getMyAdmissionStatus: uGradAdmissions.CheckMyAdmissionStatus,
};
const mapStateToProps = (state: any) => ({
    uGradCheckMyStatusRequest: state.allDdmissionsReducers.uGradCheckMyStatus,
})
export default connect(mapStateToProps, mapDispatchToProps)(CheckCurrentStatusWrap);