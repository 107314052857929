import { useEffect, useState } from "react";
import "./index.scss";

const StudentInfo = ({ studentInfo }: any) => {
  const [imgUrl, setImgUrl] = useState("");
  const storageBaseUrl =
    window.location.hostname !== "localhost" &&
    window.location.hostname !==
      "https://eduportal-staging-ibbu-webapp.azurewebsites.net"
      ? "https://eduportalprodstg.blob.core.windows.net/eduportal-prod-ibbu-container/UNDERGRADUATE_PASSPORTS"
      : "https://eduportalstagingstg.blob.core.windows.net/eduportal-staging-ibbu-container/UNDERGRADUATE_PASSPORTS";

  function imageUrlToBase64(
    url: string,
    callback: (base64Img: string) => void
  ) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL("image/jpeg");
      callback(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
    };

    img.src = url;
  }

  useEffect(() => {
    if (studentInfo?.photoId) {
      imageUrlToBase64(
        `${storageBaseUrl}/${studentInfo?.photoId}`,
        function (base64Img) {
          setImgUrl(base64Img);
        }
      );
    }
  }, [studentInfo?.photoId]);

  return (
    <>
      <p className="card">Examination Card</p>
      <div className="student-details">
        <div className="table">
          <table border={1}>
            <tbody>
              <tr>
                <td>Name </td>
                <td>{studentInfo?.fullName}</td>
              </tr>
              <tr>
                <td>Matric No </td>
                <td>{studentInfo?.matricNumber}</td>
              </tr>
              <tr>
                <td>Faculty </td>
                <td>{studentInfo?.faculty}</td>
              </tr>
              <tr>
                <td>Department </td>
                <td>{studentInfo?.department}</td>
              </tr>
              <tr>
                <td>Level </td>
                <td>{studentInfo?.level}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="passport">
          <img src={imgUrl} />
        </div>
      </div>
    </>
  );
};

export default StudentInfo;
