import { ApiService } from "../../../../services/apiService";
import { routes } from "../../../../services/paths";
import { Dispatch } from 'redux';

import { appConstantsUGradOPortal } from "../../../action-constants/portal/undergraduate";
import {
    handleRequestErrors,
} from "../../../../shared/utils";


const UnderGradProfile = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                    loggedAccountInfo = JSON.parse(loggedAccountInfo);
                    loggedAccountInfo.profile = response.data;
                    localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));

                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.GET_PROFILE_DATA_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GET_PROFILE_DATA_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GET_PROFILE_DATA_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GET_PROFILE_DATA_RESET, clear_data: "" };
    }
}


const UnderGradGetAcceptanceFeeReceipt = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    if (response?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));


                        let feePayload = {
                            studentUniqueId: response?.data.studentUniqueId,
                            matricNumber: response?.data.matricNumber,
                            level: response?.data.level,
                            fullName: response?.data.fullName,
                            session: response?.data.currentSession,
                            email: response?.data.email,
                            phoneNumber: response?.data.phoneNumber,
                            faculty: response?.data.faculty,
                            department: response?.data.department,
                            programme: response?.data.programme,
                        }
                        let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_ACCEPTANCE_FEE}`, http_method: "POST", data: feePayload, headers: { "X-Signature": routes.apiKey } });
                        dispatch(request(apiConsume2));
                        return apiConsume2
                            .then((response2: any) => {
                                // window.open(response2.data, '_blank');
                                dispatch(success(response2.data))
                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });


                    } else {
                        dispatch(failure("Unable to get your profile please refresh this page or try again later"));
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };


    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_RESET, clear_data: "" };
    }
}

const UnderGradGetFacultyFee = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    if (response?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));

                        if (response?.data?.hasPaidSchoolFee) {
                            // if (response?.data?.hasUpdatedBioData) {
                            let feePayload = {
                                studentUniqueId: response?.data.studentUniqueId,
                                matricNumber: response?.data.matricNumber,
                                level: response?.data.level,
                                fullName: response?.data.fullName,
                                session: response?.data.currentSession,
                                email: response?.data.email,
                                phoneNumber: response?.data.phoneNumber,
                                faculty: response?.data.faculty,
                                department: response?.data.department,
                                programme: response?.data.programme,
                            }
                            let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_FACULTY_FEE}`, http_method: "POST", data: feePayload, headers: { "X-Signature": routes.apiKey } });
                            dispatch(request(apiConsume2));
                            return apiConsume2
                                .then((response2: any) => {
                                    dispatch(success(response2.data))
                                })
                                .catch((error: any) => {
                                    dispatch(failure(handleRequestErrors(error)));
                                });
                            // } else {
                            //     dispatch(failure("You must update your Bio-data before paying other fees"));
                            // }
                        } else {
                            dispatch(failure("You are required to pay your school fee before paying other fees"));
                        }

                    } else {
                        dispatch(failure("Unable to get your profile please refresh this page or try again later"));
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };


    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.GENERATE_FACULTYFEE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GENERATE_FACULTYFEE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GENERATE_FACULTYFEE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GENERATE_FACULTYFEE_RESET, clear_data: "" };
    }
}

const UnderGradGetGstFee = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    if (response?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));

                        if (response?.data?.hasPaidSchoolFee) {
                            // if (response?.data?.hasUpdatedBioData) {
                            let feePayload = {
                                studentUniqueId: response?.data.studentUniqueId,
                                matricNumber: response?.data.matricNumber,
                                level: response?.data.level,
                                fullName: response?.data.fullName,
                                session: response?.data.currentSession,
                                email: response?.data.email,
                                phoneNumber: response?.data.phoneNumber,
                                faculty: response?.data.faculty,
                                department: response?.data.department,
                                programme: response?.data.programme,
                            }
                            let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_GST_FEE}`, http_method: "POST", data: feePayload, headers: { "X-Signature": routes.apiKey } });
                            dispatch(request(apiConsume2));
                            return apiConsume2
                                .then((response2: any) => {
                                    dispatch(success(response2.data))
                                })
                                .catch((error: any) => {
                                    dispatch(failure(handleRequestErrors(error)));
                                });
                            // } else {
                            //     dispatch(failure("You must update your Bio-data before paying other fees"));
                            // }
                        } else {
                            dispatch(failure("You are required to pay your school fee before paying other fees"));
                        }
                    } else {
                        dispatch(failure("Unable to get your profile please refresh this page or try again later"));
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.GENERATE_GSTFEE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GENERATE_GSTFEE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GENERATE_GSTFEE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GENERATE_GSTFEE_RESET, clear_data: "" };
    }
}

const UnderGradGetNanissFee = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    if (response?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));

                        if (response?.data?.hasPaidSchoolFee) {
                            // if (response?.data?.hasUpdatedBioData) {
                            let feePayload = {
                                studentUniqueId: response?.data.studentUniqueId,
                                matricNumber: response?.data.matricNumber,
                                level: response?.data.level,
                                fullName: response?.data.fullName,
                                session: response?.data.currentSession,
                                email: response?.data.email,
                                phoneNumber: response?.data.phoneNumber,
                                faculty: response?.data.faculty,
                                department: response?.data.department,
                                programme: response?.data.programme,
                            }
                            let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_NANISS_FEE}`, http_method: "POST", data: feePayload, headers: { "X-Signature": routes.apiKey } });
                            dispatch(request(apiConsume2));
                            return apiConsume2
                                .then((response2: any) => {
                                    dispatch(success(response2.data))
                                })
                                .catch((error: any) => {
                                    dispatch(failure(handleRequestErrors(error)));
                                });
                            // } else {
                            //     dispatch(failure("You must update your Bio-data before paying other fees"));
                            // }
                        } else {
                            dispatch(failure("You are required to pay your school fee before paying other fees"));
                        }
                    } else {
                        dispatch(failure("Unable to get your profile please refresh this page or try again later"));
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.GENERATE_NANISS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GENERATE_NANISS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GENERATE_NANISS_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GENERATE_NANISS_RESET, clear_data: "" };
    }
}

const UnderGradGetEntrepreneurFee = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    if (response?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));
                        if (response?.data?.hasPaidSchoolFee) {
                            // if (response?.data?.hasUpdatedBioData) {
                            let feePayload = {
                                studentUniqueId: response?.data.studentUniqueId,
                                matricNumber: response?.data.matricNumber,
                                level: response?.data.level,
                                fullName: response?.data.fullName,
                                session: response?.data.currentSession,
                                email: response?.data.email,
                                phoneNumber: response?.data.phoneNumber,
                                faculty: response?.data.faculty,
                                department: response?.data.department,
                                programme: response?.data.programme,
                            }
                            let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_ENTREPRENEUR_FEE}`, http_method: "POST", data: feePayload, headers: { "X-Signature": routes.apiKey } });
                            dispatch(request(apiConsume2));
                            return apiConsume2
                                .then((response2: any) => {
                                    dispatch(success(response2.data))
                                })
                                .catch((error: any) => {
                                    dispatch(failure(handleRequestErrors(error)));
                                });
                            // } else {
                            //     dispatch(failure("You must update your Bio-data before paying other fees"));
                            // }

                        } else {
                            dispatch(failure("You are required to pay your school fee before paying other fees"));
                        }


                    } else {
                        dispatch(failure("Unable to get your profile please refresh this page or try again later"));
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.GENERATE_ENT_FEE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GENERATE_ENT_FEE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GENERATE_ENT_FEE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GENERATE_ENT_FEE_RESET, clear_data: "" };
    }
}

const UnderGradGetSugFee = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    if (response?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));

                        if (response?.data?.hasPaidSchoolFee) {
                            // if (response?.data?.hasUpdatedBioData) {
                            let feePayload = {
                                studentUniqueId: response?.data.studentUniqueId,
                                matricNumber: response?.data.matricNumber,
                                level: response?.data.level,
                                fullName: response?.data.fullName,
                                session: response?.data.currentSession,
                                email: response?.data.email,
                                phoneNumber: response?.data.phoneNumber,
                                faculty: response?.data.faculty,
                                department: response?.data.department,
                                programme: response?.data.programme,
                            }
                            let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_SUG_FEE}`, http_method: "POST", data: feePayload, headers: { "X-Signature": routes.apiKey } });
                            dispatch(request(apiConsume2));
                            return apiConsume2
                                .then((response2: any) => {
                                    dispatch(success(response2.data))
                                })
                                .catch((error: any) => {
                                    dispatch(failure(handleRequestErrors(error)));
                                });
                            // } else {
                            //     dispatch(failure("You must update your Bio-data before paying other fees"));
                            // }

                        } else {
                            dispatch(failure("You are required to pay your school fee before paying other fees"));
                        }

                    } else {
                        dispatch(failure("Unable to get your profile please refresh this page or try again later"));
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.GENERATE_SUGFEE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GENERATE_SUGFEE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GENERATE_SUGFEE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GENERATE_SUGFEE_RESET, clear_data: "" };
    }
}

const UnderGradGetSchoolFee = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    let feePayload: any
                    if (response?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));
                        feePayload = {
                            studentUniqueId: response?.data.studentUniqueId,
                            matricNumber: response?.data.matricNumber,
                            level: response?.data.level,
                            fullName: response?.data.fullName,
                            session: response?.data.currentSession,
                            email: response?.data.email,
                            phoneNumber: response?.data.phoneNumber,
                            faculty: response?.data.faculty,
                            department: response?.data.department,
                            programme: response?.data.programme,


                        }

                        let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_SCHOOL_FEE}`, http_method: "POST", data: feePayload, headers: { "X-Signature": routes.apiKey } });
                        dispatch(request(apiConsume2));
                        return apiConsume2
                            .then((response2: any) => {
                                dispatch(success(response2.data))
                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });
                        // } else {
                        //     dispatch(failure("You must update your Bio-data before paying other fees"));
                        // }
                        // } else {
                        //     feePayload = {
                        //         studentUniqueId: response?.data.studentUniqueId,
                        //         matricNumber: response?.data.matricNumber,
                        //         level: response?.data.level,
                        //         fullName: response?.data.fullName,
                        //         session: response?.data.currentSession,
                        //         email: response?.data.email,
                        //         phoneNumber: response?.data.phoneNumber,
                        //         faculty: response?.data.faculty,
                        //         department: response?.data.department,
                        //         programme: response?.data.programme,


                        //     }
                        //     let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_SCHOOL_FEE}`, http_method: "POST", data: feePayload, headers: { "X-Signature": routes.apiKey } });
                        //     dispatch(request(apiConsume2));
                        //     return apiConsume2
                        //         .then((response2: any) => {
                        //             dispatch(success(response2.data))
                        //         })
                        //         .catch((error: any) => {
                        //             dispatch(failure(handleRequestErrors(error)));
                        //         });
                        // }
                    } else {
                        dispatch(failure("Unable to get your profile please refresh this page or try again later"));
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.GENERATE_SCHOOLFEE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GENERATE_SCHOOLFEE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GENERATE_SCHOOLFEE_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GENERATE_SCHOOLFEE_RESET, clear_data: "" };
    }
}

const UnderGradLoadPresetCourseReg = (actionPayload: any) => {
    let entrepreneurFeeExemptionList = [
        { programme: "FOOD SCIENCE AND TECHNOLOGY", level: "400" },
        { programme: "AGRICULTURE", level: "400" },
    ]


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    if (response?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));
                    }
                    let isEntrepreneurFeeExempted = entrepreneurFeeExemptionList.filter((eachItem) => eachItem.programme.toLowerCase() === response.data?.programme.toLowerCase() && eachItem.level === response.data?.level)

                    let skipGST = response?.data.level === "400" || response?.data.level === "500" || response?.data.level === "600";

                    if (response?.data?.hasPaidSchoolFee && (skipGST || response?.data?.hasPaidGstFee) && response?.data?.hasPaidFacultyFee && ((isEntrepreneurFeeExempted.length === 0 && response?.data?.hasPaidEntrepreneurshipFee) || isEntrepreneurFeeExempted.length >= 1) && response?.data?.hasPaidSugFee) {
                        let coursesPayload = {
                            studentId: response?.data.studentUniqueId,
                            matricNumber: response?.data.matricNumber,
                            level: response?.data.level,
                            session: response?.data.currentSession,
                            programme: response?.data.programme,
                        }
                        let apiConsume2 = ApiService.request({ url: `${routes.GET_ALL_COURSE_SUBJECTS}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                        dispatch(request(apiConsume2));
                        return apiConsume2
                            .then((response2: any) => {

                                if (response2?.data && response2?.data.length >= 1 && response?.data?.isSpillOver === false) {
                                    let apiConsume3 = ApiService.request({ url: `${routes.UNDERGRAD_LOAD_PRESET_COURSES}`, http_method: "POST", data: coursesPayload, headers: { "X-Signature": routes.apiKey } });
                                    // dispatch(request(apiConsume3));
                                    return apiConsume3
                                        .then((response3: any) => {
                                            // let result = {
                                            //     profile: response?.data,
                                            //     allSubjectsList: response2?.data,
                                            //     presetCourseList: response3?.data,
                                            // }
                                            // dispatch(success(result))
                                            let maxPayload = {
                                                session: response?.data.currentSession,
                                                programme: response?.data.programme,
                                                studentId: response?.data.studentUniqueId,
                                                matricNumber: response?.data.matricNumber,
                                                studentLevel: response?.data.level
                                            }
                                            if (response3?.data) {
                                                let apiConsume4 = ApiService.request({ url: `${routes.UNDERGRAD_LOAD_MAX_COURSES}`, http_method: "POST", data: maxPayload, headers: { "X-Signature": routes.apiKey } });
                                                // dispatch(request(apiConsume4));
                                                return apiConsume4
                                                    .then((response4: any) => {
                                                        let regCoursesPayload = {
                                                            session: response?.data.currentSession,
                                                            programme: response?.data.programme,
                                                            studentUniqueId: response?.data.studentUniqueId,
                                                            matricNumber: response?.data.matricNumber,
                                                            level: response?.data.level,
                                                            email: response?.data?.email,
                                                            phoneNumber: response?.data?.phoneNumber,
                                                            fullName: response?.data?.fullName,
                                                            faculty: response?.data?.faculty,
                                                            department: response?.data?.department,

                                                        }
                                                        let apiConsume5 = ApiService.request({ url: `${routes.UNDERGRAD_LOAD_MY_REG_COURSES}`, http_method: "POST", data: regCoursesPayload, headers: { "X-Signature": routes.apiKey } });
                                                        // dispatch(request(apiConsume5));
                                                        return apiConsume5
                                                            .then((response5: any) => {
                                                                let apiConsume6 = ApiService.request({ url: `${routes.GET_CURRENT_SESSION}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                                                                // dispatch(request(apiConsume6));
                                                                return apiConsume6
                                                                    .then((response6: any) => {
                                                                        let result = {
                                                                            profile: response?.data,
                                                                            allSubjectsList: response2?.data,
                                                                            presetCourseList: response3?.data,
                                                                            maxCourses: response4?.data,
                                                                            registeredCourses: response5?.data,
                                                                            currentSession: response6?.data,
                                                                            isSpillOver: response?.data?.isSpillOver
                                                                        }
                                                                        dispatch(success(result))
                                                                    })
                                                                    .catch((error: any) => {
                                                                        dispatch(failure(handleRequestErrors(error)));
                                                                    });
                                                            })
                                                            .catch((error: any) => {
                                                                dispatch(failure(handleRequestErrors(error)));
                                                            });
                                                    })
                                                    .catch((error: any) => {
                                                        dispatch(failure(handleRequestErrors(error)));
                                                    });
                                            } else {
                                                dispatch(failure("An error occured. Please refresh your page or try again later"));
                                            }
                                        })
                                        .catch((error: any) => {
                                            dispatch(failure(handleRequestErrors(error)));
                                        });

                                } else {
                                    if (response?.data?.isSpillOver === true) {
                                        let maxPayload = {
                                            session: response?.data.currentSession,
                                            programme: response?.data.programme,
                                            studentId: response?.data.studentUniqueId,
                                            matricNumber: response?.data.matricNumber,
                                            studentLevel: response?.data.level
                                        }
                                        let apiConsume4 = ApiService.request({ url: `${routes.UNDERGRAD_LOAD_MAX_COURSES}`, http_method: "POST", data: maxPayload, headers: { "X-Signature": routes.apiKey } });
                                        // dispatch(request(apiConsume4));
                                        return apiConsume4
                                            .then((response4: any) => {
                                                let regCoursesPayload = {
                                                    session: response?.data.currentSession,
                                                    programme: response?.data.programme,
                                                    studentUniqueId: response?.data.studentUniqueId,
                                                    matricNumber: response?.data.matricNumber,
                                                    level: response?.data.level,
                                                    email: response?.data?.email,
                                                    phoneNumber: response?.data?.phoneNumber,
                                                    fullName: response?.data?.fullName,
                                                    faculty: response?.data?.faculty,
                                                    department: response?.data?.department,

                                                }
                                                let apiConsume5 = ApiService.request({ url: `${routes.UNDERGRAD_LOAD_MY_REG_COURSES}`, http_method: "POST", data: regCoursesPayload, headers: { "X-Signature": routes.apiKey } });
                                                // dispatch(request(apiConsume5));
                                                return apiConsume5
                                                    .then((response5: any) => {
                                                        let apiConsume6 = ApiService.request({ url: `${routes.GET_CURRENT_SESSION}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                                                        // dispatch(request(apiConsume6));
                                                        return apiConsume6
                                                            .then((response6: any) => {
                                                                let result = {
                                                                    profile: response?.data,
                                                                    allSubjectsList: response2?.data,
                                                                    presetCourseList: [],
                                                                    maxCourses: response4?.data,
                                                                    registeredCourses: response5?.data,
                                                                    currentSession: response6?.data,
                                                                    isSpillOver: response?.data?.isSpillOver
                                                                }
                                                                dispatch(success(result))
                                                            })
                                                            .catch((error: any) => {
                                                                dispatch(failure(handleRequestErrors(error)));
                                                            });
                                                    })
                                                    .catch((error: any) => {
                                                        dispatch(failure(handleRequestErrors(error)));
                                                    });
                                            })
                                            .catch((error: any) => {
                                                dispatch(failure(handleRequestErrors(error)));
                                            });
                                    }
                                    dispatch(failure("An error occured. Please refresh your page or try again later"));
                                }
                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });
                        // } else {
                        //     dispatch(failure("You must update your Bio-data before you can do course registration."));
                        // }

                    } else {
                        dispatch(failure("You must make payment for all your fees before you can do course registration."));
                        // if (response?.data?.hasPaidSchoolFee) {
                        //     dispatch(failure("You have not paid your school fees. Course registration will be avilable to you after paying your School Fees"));
                        // }
                        // if (!response?.data?.hasPaidGstFee) {
                        //     dispatch(failure("You have not paid your GST fees. Course registration will be avilable to you after paying your GST Fees"));
                        // }
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_RESET, clear_data: "" };
    }
}

const UnderGradSaveCourseReg = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UGRAD_SAVE_COURSE_REG}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response2: any) => {
                    dispatch(success(response2.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };


    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_RESET, clear_data: "" };
    }
}

const UnderGradPrintExamCardReg = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_GET_EXAMCARD}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response2: any) => {
                    dispatch(success(response2.data))
                })
                .catch((error: any) => {
                    if (error?.response?.status >= 400 && error?.response?.status < 500) {
                        dispatch(failure(handleRequestErrors(error)));
                    }
                    else {
                        dispatch(failure(handleRequestErrors("An error occured. Please refresh your page or try again later")));
                    }
                });
        };


    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.PRINT_EXAM_CARD_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.PRINT_EXAM_CARD_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.PRINT_EXAM_CARD_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.PRINT_EXAM_CARD_RESET, clear_data: "" };
    }
}

const UnderGradPrintCourseReg = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.PRINT_COURSE_REG}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response2: any) => {
                    window.open(response2.data, '_blank');
                    dispatch(success(response2.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };


    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.PRINT_COURSE_REG_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.PRINT_COURSE_REG_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.PRINT_COURSE_REG_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.PRINT_COURSE_REG_RESET, clear_data: "" };
    }
}

const UnderGradDeletePresetCourseReg = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.DELETE_PRESET_COURSES}${actionPayload}`, http_method: "DELETE", data: null, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_RESET, clear_data: "" };
    }
}

const UnderGradGetBioData = (actionPayload: any, isEdit?: boolean) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_STUDENT_BIODATA}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response2: any) => {

                    if (response2?.data) {
                        let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                        loggedAccountInfo = JSON.parse(loggedAccountInfo);
                        loggedAccountInfo.profile = response2.data;
                        localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));
                    }
                    
                    if (response2.data?.hasPaidSchoolFee) {
                        if (isEdit) {
                            if ((String(response2?.data?.level) === "500" && response2?.data?.department === "AGRICULTURE" && response2?.data?.faculty === "AGRICULTURE")
                                || response2?.data?.matricNumber.indexOf("SED") > -1) {


                                let apiConsume3 = ApiService.request({ url: `${routes.GET_ALL_PROGRAMMES}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                                dispatch(request(apiConsume3));
                                return apiConsume3
                                    .then((response3: any) => {


                                        // dispatch(success(responses))

                                        let apiConsume4 = ApiService.request({ url: `${routes.GET_ALL_AWARDS}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                                        dispatch(request(apiConsume4));
                                        return apiConsume4
                                            .then((response4: any) => {

                                                let apiConsume5 = ApiService.request({ url: `${routes.GET_ALL_HIGHEST_QUALIFICATION}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                                                dispatch(request(apiConsume5));
                                                return apiConsume5
                                                    .then((response5: any) => {

                                                        let allProgrammes
                                                        if (String(response2?.data?.level) === "500" && response2?.data?.department === "AGRICULTURE" && response2?.data?.faculty === "AGRICULTURE") {
                                                            allProgrammes = response3?.data.filter((eachItem: any) => eachItem?.departmentUniqueId === routes.agricId && eachItem?.programmeName.toLowerCase() !== "agriculture")
                                                        }
                                                        if (response2?.data?.matricNumber.indexOf("SED") > -1) {
                                                            allProgrammes = response3?.data.filter((eachItem: any) => eachItem?.departmentUniqueId === routes.sedId)
                                                        }
                                                        let responses = {
                                                            ...response2.data,
                                                            allProgrammes,
                                                            allAwards: response4.data,
                                                            allQualification: response5.data
                                                        }
                                                        dispatch(success(responses))
                                                    })
                                                    .catch((error3: any) => {
                                                        dispatch(failure(handleRequestErrors(error3)));
                                                    });
                                            })
                                            .catch((error3: any) => {
                                                dispatch(failure(handleRequestErrors(error3)));
                                            });
                                    })
                                    .catch((error3: any) => {
                                        dispatch(failure(handleRequestErrors(error3)));
                                    });
                            } else {

                                let apiConsume4 = ApiService.request({ url: `${routes.GET_ALL_AWARDS}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                                dispatch(request(apiConsume4));
                                return apiConsume4
                                    .then((response4: any) => {

                                        let apiConsume5 = ApiService.request({ url: `${routes.GET_ALL_HIGHEST_QUALIFICATION}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
                                        dispatch(request(apiConsume5));
                                        return apiConsume5
                                            .then((response5: any) => {


                                                let responses = {
                                                    ...response2.data,

                                                    allAwards: response4.data,
                                                    allQualification: response5.data
                                                }
                                                dispatch(success(responses))
                                            })
                                            .catch((error5: any) => {
                                                dispatch(failure(handleRequestErrors(error5)));
                                            });
                                    })
                                    .catch((error4: any) => {
                                        dispatch(failure(handleRequestErrors(error4)));
                                    });
                                // dispatch(success(response2.data))
                            }
                        } else {
                            dispatch(success(response2?.data))
                        }
                    } else {
                        dispatch(failure("You are required to pay your school fee to have access to your biodata."));
                    }
                    
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };


    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {

        return { type: appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_RESET, clear_data: "" };
    }
}

const UnderGradPrintBioData = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.PRINT_STUDENT_BIODATA}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {


                    if (response.data) {
                        dispatch(success(response))
                        window.open(response.data, '_blank');
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_RESET, clear_data: "" };
    }
}

const UnderGradUpdateBioData = (actionPayload: any, passport: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            // if (!actionPayload?.photoId) {
            if (passport) {
                let apiConsume = ApiService.request({ url: `${routes.UPLOAD_STUDENT_PASSPORT}`, http_method: "POST", data: passport, headers: { "X-Signature": routes.apiKey } });
                dispatch(request(apiConsume));
                return apiConsume
                    .then((response: any) => {


                        if (response?.data) {
                            let responseData = response?.data

                            let photoId = responseData.substring(responseData.lastIndexOf('/') + 1)
                            // let bioData = {
                            //     ...actionPayload,
                            //     photoId
                            // }

                            let bioData = actionPayload;
                            bioData.photoId = photoId;

                            let apiConsume2 = ApiService.request({ url: `${routes.UPDATE_STUDENT_BIODATA}`, http_method: "POST", data: bioData, headers: { "X-Signature": routes.apiKey } });
                            dispatch(request(apiConsume2));
                            return apiConsume2
                                .then((response2: any) => {

                                    dispatch(success(response2.data))
                                })
                                .catch((error: any) => {
                                    dispatch(failure(handleRequestErrors(error)));
                                });
                        }
                    })
                    .catch((error: any) => {

                        dispatch(failure(handleRequestErrors(error)));
                    });
            } else {
                let apiConsume = ApiService.request({ url: `${routes.UPDATE_STUDENT_BIODATA}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
                dispatch(request(apiConsume));
                return apiConsume
                    .then((response: any) => {

                        dispatch(success(response.data))
                    })
                    .catch((error: any) => {
                        dispatch(failure(handleRequestErrors(error)));
                    });
            }
        };


    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_RESET, clear_data: "" };
    }
}

const ChangeStudentPasssword = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {
            let url = `${routes.CHANGE_STUDENT_PASSWORD}`;
            let http_method = "POST";
            let apiConsume = ApiService.request({ url, http_method, data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                    loggedAccountInfo = JSON.parse(loggedAccountInfo);

                    let payload = {
                        matricNumber: loggedAccountInfo.profile?.matricNumber
                    }
                    let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: payload, headers: { "X-Signature": routes.apiKey } });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {

                            loggedAccountInfo.profile = response2.data;
                            localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));

                            dispatch(success(response))
                        })
                        .catch((error: any) => {
                            dispatch(success(response))
                            // dispatch(failure(handleRequestErrors(error)));
                        });
                    // dispatch(success(response))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_RESET, clear_data: "" };
    }
}

const checkStudentAdditionalPayment = async (studentId: string) => {
    return await ApiService.request({
        url: routes.CHECK_ADDITIONAL_FEE,
        http_method: "POST",
        data: { studentId },
        headers: { "X-Signature": routes.apiKey }
    }
    );
}

export const uGradPortal = {
    UnderGradProfile,
    UnderGradGetAcceptanceFeeReceipt,
    UnderGradGetFacultyFee,
    UnderGradGetGstFee,
    UnderGradGetEntrepreneurFee,
    UnderGradGetNanissFee,
    UnderGradGetSugFee,
    UnderGradGetSchoolFee,
    UnderGradLoadPresetCourseReg,
    UnderGradSaveCourseReg,
    UnderGradDeletePresetCourseReg,
    UnderGradPrintCourseReg,
    UnderGradPrintExamCardReg,

    UnderGradGetBioData,
    UnderGradPrintBioData,
    UnderGradUpdateBioData,

    ChangeStudentPasssword,
    checkStudentAdditionalPayment
}