import { useState } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import "./index.scss";
import BlueEye from "../../../assets/Blue-Eye.png";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../hooks/useWindowSize";

const PaymentHistory = () => {
  const { compulsoryFeeHistories } = useSelector(
    (state: any) => state.eDocumentation
  );
  const screenResolution: any = useWindowSize();
  return (
    <div className="clearance-table-container">
      <Table bordered hover className="clearance-request-table">
        <thead>
          <tr>
            <th>GENERATED ON</th>
            <th>SESSION</th>
            <th>TRANSACTION ID</th>
            <th>PAYMENT STATUS</th>
            <th>AMOUNT</th>
            <th>DOWNLOAD</th>
          </tr>
        </thead>
        <tbody>
          {compulsoryFeeHistories?.length ? (
            compulsoryFeeHistories?.map((history: any, index: any) => {
              return (
                <tr key={index}>
                  <td>{history.generatedOn}</td>
                  <td>{history?.session}</td>
                  <td>{history?.transactionId}</td>
                  <td
                    className={
                      history?.paymentStatus === "PAID"
                        ? "paid-payment-status"
                        : ""
                    }
                  >
                    {history?.paymentStatus}
                  </td>
                  <td>{history?.amount}</td>
                  <td>
                    <a target="_blank" href={history?.downloadUrl}>
                      {screenResolution?.width >= 760
                        ? "Download Receipt"
                        : "Donwload"}
                    </a>
                  </td>
                </tr>
              );
            })
          ) : (
            <p className="no-results">No results...</p>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default PaymentHistory;
