import ExamRules from "./ExamRules";
import FrontPage from "./FrontPage";
import "./index.scss";

const StudentExamCard = () => {
  return (
    <div className="main-container" id="table-to-export">
      <div className="main-wrapper">
        <FrontPage />
        <ExamRules />
      </div>
    </div>
  );
};

export default StudentExamCard;
