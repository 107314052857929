export const appConstantsUGradAdmisssions ={

    CHECK_ADMISSION_PROCESS_STATUS_SUCCESS : 'CHECK_ADMISSION_PROCESS_STATUS_SUCCESS',
    CHECK_ADMISSION_PROCESS_STATUS_PENDING : 'CHECK_ADMISSION_PROCESS_STATUS_PENDING',
    CHECK_ADMISSION_PROCESS_STATUS_FAILURE : 'CHECK_ADMISSION_PROCESS_STATUS_FAILURE',
    CHECK_ADMISSION_PROCESS_STATUS_RESET : 'CHECK_ADMISSION_PROCESS_STATUS_RESET',

    GET_ACCEPTANCE_INVOICE_SUCCESS : 'GET_ACCEPTANCE_INVOICE_SUCCESS',
    GET_ACCEPTANCE_INVOICE_PENDING : 'GET_ACCEPTANCE_INVOICE_PENDING',
    GET_ACCEPTANCE_INVOICE_FAILURE : 'GET_ACCEPTANCE_INVOICE_FAILURE',
    GET_ACCEPTANCE_INVOICE_RESET : 'GET_ACCEPTANCE_INVOICE_RESET',

    GET_SCHOOL_FEE_INVOICE_SUCCESS : 'GET_SCHOOL_FEE_INVOICE_SUCCESS',
    GET_SCHOOL_FEE_INVOICE_PENDING : 'GET_SCHOOL_FEE_INVOICE_PENDING',
    GET_SCHOOL_FEE_INVOICE_FAILURE : 'GET_SCHOOL_FEE_INVOICE_FAILURE',
    GET_SCHOOL_FEE_INVOICE_RESET : 'GET_SCHOOL_FEE_INVOICE_RESET',

    GET_ADMISSION_LETTER_SUCCESS : 'GET_ADMISSION_LETTER_SUCCESS',
    GET_ADMISSION_LETTER_PENDING : 'GET_ADMISSION_LETTER_PENDING',
    GET_ADMISSION_LETTER_FAILURE : 'GET_ADMISSION_LETTER_FAILURE',
    GET_ADMISSION_LETTER_RESET : 'GET_ADMISSION_LETTER_RESET',

}