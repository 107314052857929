import { useSelector } from "react-redux";
import CourseList from "./CourseList";
import IbbuHeader from "./IbbuHeader";
import StudentInfo from "./StudentInfo";
import "./index.scss";
import QRCodeGenerator from "../../shared/components/QRCodeGenerator";

const FrontPage = () => {
  const state = useSelector(
    (state: any) => state.allUGradPortalReducers.UnderGradPrintExamCard
  );
  const studentInfo = state?.request_data?.response;
  const studentDetails = useSelector(
    (state: any) => state.allOnboardingReducers?.uGradLogin
  );
  const { studentUniqueId, currentSession } =
    studentDetails?.request_data?.response?.profile;

  const qrCodeValue = `/Verify/UndergraduateExamCard?verificationcode=${studentUniqueId}&session=${currentSession}`;
  return (
    <div className="front-page-wrapper">
      <IbbuHeader />
      <StudentInfo studentInfo={studentInfo} />
      <CourseList studentInfo={studentInfo} />

      {/* Signature */}
      <div className="sign">
        <p>Examination Officer's Signature & Stamp :</p>
        <hr />
      </div>

      {/* NOTES */}
      <div className="note">
        <div>
          <h5>NOTE:</h5>
          <p>
            You will not be allowed to sit for any paper without the
            presentation of this card. This card must be printed in colors to
            make it valid. Black & White copy will not be accepted.
          </p>
        </div>
        <QRCodeGenerator value={qrCodeValue} />
      </div>
    </div>
  );
};

export default FrontPage;
