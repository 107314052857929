import {
    uGradCheckAdmissionStatusReducer,
    uGradPrintMyAcceptanceInvoiceReducer,
    uGradPrintMySchoolFeeInvoiceReducer,
    uGradPrintMyAdmissionLetterReducer 
} from "./undergraduate/ugrad-admissions"



export const admissionsReducers = {
    uGradCheckAdmissionStatusReducer,
    uGradPrintMyAcceptanceInvoiceReducer,
    uGradPrintMySchoolFeeInvoiceReducer,
    uGradPrintMyAdmissionLetterReducer
}