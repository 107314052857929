import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { Dispatch } from 'redux';
import { history } from "../../../shared/_helpers/history";

import { appConstantsUGradOnboarding } from "../../action-constants/onboarding";

import {
    handleRequestErrors,
} from "../../../shared/utils";
import { CLEAR_STATE } from "../../action-constants/edocumentation";


const UnderGradLogin = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {
            let userData: any;
            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_LOGIN}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    userData = { ...response.data };
                    userData.lastLogForAuth = Date.now();
                    localStorage.setItem("stuXAuth", JSON.stringify(userData));
                    let matricNumber = actionPayload?.matricNumber




                    let apiConsume2 = ApiService.request({ url: `${routes.UNDERGRAD_GET_PROFILE}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {
                            let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
                            loggedAccountInfo = JSON.parse(loggedAccountInfo);
                            loggedAccountInfo.profile = response2.data;
                            localStorage.setItem("stuXAuth", JSON.stringify(loggedAccountInfo));

                            dispatch(success(loggedAccountInfo, matricNumber))
                            if (response2?.data?.hasChangedDefaultPassword) {
                                history.replace("/undergraduate/dashboard")
                            }
                            if (!response2?.data?.hasChangedDefaultPassword) {
                                history.replace("/undergraduate/update-password")
                            }
                        })
                        .catch((error: any) => {

                            dispatch(failure(handleRequestErrors(error)));
                            localStorage.removeItem("stuXAuth");
                            localStorage.removeItem("state");
                        
                        });
                })
                .catch((error: any) => {
                    if (error.response?.data == "invalid_grant") {
                        dispatch(failure("Invalid Matric Number or Password"));
                    } else {
                        dispatch(failure(handleRequestErrors(error)));
                    }
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOnboarding.LOGIN_USER_PENDING, params };
    }
    function success(response: any, matricNumber: any) {
        return { type: appConstantsUGradOnboarding.LOGIN_USER_SUCCESS, response, matricNumber };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOnboarding.LOGIN_USER_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOnboarding.LOGIN_USER_RESET, clear_data: "" };
    }
}

const UnderGradForgotPw = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {
            
            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_FORGOT_PW}${actionPayload}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response?.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOnboarding.FORGOT_PASSWORD_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOnboarding.FORGOT_PASSWORD_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOnboarding.FORGOT_PASSWORD_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOnboarding.FORGOT_PASSWORD_RESET, clear_data: "" };
    }
}

const UnderGradVerifyForgotPwCode = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {
           
            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_VERIFY_PW_TOKEN}${actionPayload}`, http_method: "GET", data: null, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response?.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOnboarding.VERFIY_PASSWORD_TOKEN_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOnboarding.VERFIY_PASSWORD_TOKEN_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOnboarding.VERFIY_PASSWORD_TOKEN_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOnboarding.VERFIY_PASSWORD_TOKEN_RESET, clear_data: "" };
    }
}

const UnderGradUpdateNewPassword = (actionPayload: any) => {


    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {
            
            let apiConsume = ApiService.request({ url: `${routes.UNDERGRAD_RESET_PW}`, http_method: "POST", data: actionPayload, headers: { "X-Signature": routes.apiKey } });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response?.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstantsUGradOnboarding.UPDATE_NEW_PASSWORD_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstantsUGradOnboarding.UPDATE_NEW_PASSWORD_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstantsUGradOnboarding.UPDATE_NEW_PASSWORD_FAILURE, error };
    }
    function clear() {
        return { type: appConstantsUGradOnboarding.UPDATE_NEW_PASSWORD_RESET, clear_data: "" };
    }
}


const Logout = () => {
    localStorage.removeItem("stuXAuth");

    localStorage.removeItem("state");

    history.push("/undergraduate/login");
    return (dispatch: Dispatch) => {
        dispatch({type:CLEAR_STATE})
        dispatch(logout());

    };

    function logout() {
        return { type: appConstantsUGradOnboarding.LOGOUT };
    }

}
export const resetReload = () => {
    localStorage.removeItem("adRld");
  };
  
export const uGradOnboarding = {
    UnderGradLogin,
    UnderGradForgotPw,
    UnderGradVerifyForgotPwCode,
    UnderGradUpdateNewPassword,
    Logout
}