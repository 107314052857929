
import Logo from "../../../../assets/logo2.png"
import "./index.scss"


export const AppHeader = (props: any) => {

    return (
        <div className="admission_check_header">
            <div className="logo-wrap">
                <a href="https://ibbu.edu.ng/" target="_blank"><img src={Logo} alt="Admission Logo" /></a>
                
            </div>
            <div className="head_txt_wrap">
                <div className="portal_mode">Undergraduate Student Portal</div>
                <div className="school_name_txt">Ibrahim Badamasi Babangida University</div>
            </div>
        </div>
    )
}