import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";

import { useWindowSize } from "../../../hooks/useWindowSize";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { LoadingItem } from "../../../shared/components/loading";
import { uGradPortal } from "../../../redux/action/portal/undergraduate";
import { appConstantsUGradOPortal } from "../../../redux/action-constants/portal/undergraduate";
import "./index.scss";

const getUnderGradBioDataRequest = async ({ pageProps, payload }: any) => {
  await pageProps.getUnderGradBioData(payload);
};


const printUnderGradBioDataRequest = async ({ pageProps, payload }: any) => {
  await pageProps.printUnderGradBioData(payload);
};

const StudentBiodataInfo = ({ pageProps }: any) => {
  let requestInfo =
    pageProps.UnderGradGetBioDataRequest?.request_data?.response;

    let printRequestInfo =
        pageProps.UnderGradPrintBioDataRequest;

    
    const handlePrint = ()=>{
      let payload ={
        matricNumber: requestInfo?.matricNumber
      }

      printUnderGradBioDataRequest({
        pageProps,
        payload,
      });
    }
  return (
    <div className="all_info_sections">
      {/* {!requestInfo?.hasUpdatedBioData && (
        <div className="each_info_section">
          <div className="required_action_alert">
            You <span>must update your bio-data</span> before you can take any
            action on your portal.
          </div>
        </div>
      )} */}
      {/* {requestInfo?.hasUpdatedBioData && (
        <div className="each_info_section">
          <div className="required_action_alert">
            If you need to <span>update your bio-data</span> kindly proceed to
            ICT admin office.
          </div>
        </div>
      )} */}

      {requestInfo?.photoId && (
        <div className="passport_img">
          <div>
            <img src={requestInfo?.photoId} alt="" />
          </div>
        </div>
      )}
      <div className="each_info_section">
        <div className="section_heading sided">
          BIODATA
          {/* {!requestInfo?.hasUpdatedBioData && ( */}
          <div className="bio_ctas">
            <button 
            disabled={printRequestInfo?.is_request_processing}
            onClick={handlePrint} className="btn download_btn">
              {printRequestInfo?.is_request_processing?"Please wait..":"Print"}
              
              </button>
            
            <Link className="btn" to="/undergraduate/personal-data/update">
              Update Bio Data
            </Link>
            
          </div>
            
          {/* )} */}
        </div>
        {printRequestInfo?.request_status ===
        appConstantsUGradOPortal?.PRINT_STUDENT_BIO_DATA_FAILURE && (
        <div className="justify-center">
          <AlertMsg type="error" message={printRequestInfo?.request_data.error} />
        </div>
      )}

        <div className="section_info">
          <div className="each_info">
            <span>NAME :</span> {requestInfo?.fullName}
          </div>
          <div className="each_info">
            <span>NATIONALITY :</span> {requestInfo?.nationality}
          </div>
          <div className="each_info">
            <span>MATRICULATION NO :</span> {requestInfo?.matricNumber}
          </div>
          <div className="each_info">
            <span>RELIGION :</span> {requestInfo?.religion}
          </div>
          <div className="each_info">
            <span>GENDER :</span> {requestInfo?.gender}
          </div>
          <div className="each_info">
            <span>PLACE OF BIRTH :</span> {requestInfo?.placeOfBirth}
          </div>
          <div className="each_info">
            <span>DATE OF BIRTH :</span> {requestInfo?.dateOfBirth}
          </div>
          <div className="each_info">
            <span>STATE OF ORIGIN :</span> {requestInfo?.state}
          </div>
          <div className="each_info">
            <span>MARITAL STATUS :</span> {requestInfo?.maritalStatus}
          </div>
          <div className="each_info">
            <span>LOCAL GOVT. AREA :</span> {requestInfo?.lga}
          </div>
        </div>
      </div>
      <div className="each_info_section">
        <div className="section_heading">CONTACT</div>
        <div className="section_info">
          <div className="each_info">
            <span>TELEPHONE :</span> {requestInfo?.phoneNumber}
          </div>
          <div className="each_info">
            <span>EMAIL :</span> {requestInfo?.email}
          </div>
          <div className="each_info">
            <span>TELEPHONE(NEXT OF KIN) :</span>{" "}
            {requestInfo?.nextOfKinPhoneNumber}
          </div>
          <div className="each_info">
            <span>PRESENT/CONTACT ADDRESS :</span>{" "}
            {requestInfo?.presentContactAddress}
          </div>
          <div className="each_info">
            <span>ADDRESS (NEXT OF KIN) :</span> {requestInfo?.nextOfKinAddress}
          </div>
          <div className="each_info">
            <span>PERMANANT ADDRESS :</span> {requestInfo?.permanentHomeAddress}
          </div>
          <div className="each_info">
            <span>SPONSOR TYPE :</span> {requestInfo?.sponsorType}
          </div>
          <div className="each_info">
            <span>NAME (NEXT OF KIN) :</span> {requestInfo?.nextOfKin}
          </div>
          <div className="each_info">
            <span>SPONSOR NAME :</span> {requestInfo?.sponsorName}
          </div>
          <div className="each_info">
            <span>RELATIONSHIP :</span> {requestInfo?.nextOfKinRelationship}
          </div>
          <div className="each_info">
            <span>SPONSOR ADDRESS :</span> {requestInfo?.sponsorAddress}
          </div>
        </div>
      </div>
      <div className="each_info_section">
        <div className="section_heading">ACADEMIC RECORD</div>
        <div className="section_info">
          <div className="each_info">
            <span>PROGRAMME TYPE :</span> {requestInfo?.programType}
          </div>
          <div className="each_info">
            <span>EXTRA ACTIVITIES :</span> {requestInfo?.extraActivities}
          </div>
          <div className="each_info">
            <span>ENTRY MODE :</span> {requestInfo?.modeOfEntry}
          </div>
          <div className="each_info">
            <span>STUDY MODE :</span> {requestInfo?.studyMode}
          </div>
          <div className="each_info">
            <span>FACULTY :</span> {requestInfo?.faculty}
          </div>
          <div className="each_info">
            <span>ENTRY YEAR :</span> {requestInfo?.entryYear}
          </div>
          <div className="each_info">
            <span>COURSE :</span> {requestInfo?.programme}
          </div>
          <div className="each_info">
            <span>DEPARTMENT :</span> {requestInfo?.department}
          </div>
          <div className="each_info">
            <span>AWARD IN VIEW :</span> {requestInfo?.awardInView}
          </div>
          <div className="each_info">
            <span>DURATION :</span> {requestInfo?.sponsorAddress}
          </div>
          <div className="each_info">
            <span>FIRST DEGREE :</span>{" "}
            {requestInfo?.subjectOfFirstDegree}
          </div>
          <div className="each_info">
            <span>PREVIOUS UNIVERSITY :</span>{" "}
            {requestInfo?.previousUniversity}
          </div>
          <div className="each_info">
            <span>HIGHEST QUALIFICATION :</span>{" "}
            {requestInfo?.highestQualification}
          </div>
          <div className="each_info">
            <span>INSTITUTION OBTAINED :</span>{" "}
            {requestInfo?.institutionObtained}
          </div>
        </div>
      </div>
      <div className="each_info_section ">
        <div className="section_heading">HEALTH RECORD</div>
        <div className="section_info block">
          <div className="each_info">
            <span>STATUS :</span> {requestInfo?.healthStatus}
          </div>
          <div className="each_info">
            <span>DISABILITY :</span> {requestInfo?.disability}
          </div>
          <div className="each_info">
            <span>BLOOD GROUP :</span> {requestInfo?.bloodGroup}
          </div>
          <div className="each_info">
            <span>MEDICATION :</span> {requestInfo?.medication}
          </div>
        </div>
      </div>
    </div>
  );
};

const StudentBiodataDetails = ({ pageProps }: any) => {
  let authInfo = pageProps.uGradLoginRequest.request_data;
  let requestInfo = pageProps.UnderGradGetBioDataRequest;

  useEffect(() => {
    let payload = {
      matricNumber: authInfo?.matricNumber,
    };
    getUnderGradBioDataRequest({
      pageProps,
      payload,
    });
  }, []);

  return (
    <div className="biodata_details">
      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GET_STUDENT_BIO_DATA_PENDING && (
        <>
          <LoadingItem />
        </>
      )}

      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GET_STUDENT_BIO_DATA_SUCCESS && (
        <StudentBiodataInfo pageProps={pageProps} />
      )}

      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GET_STUDENT_BIO_DATA_FAILURE && (
        <div className="justify-center">
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        </div>
      )}
    </div>
  );
};

const StudentBiodata = ({ pageProps }: any) => {
  let requestInfo = pageProps.UGradLoadPresetCoursesRequest;
  useEffect(() => {
    printUnderGradBioDataRequest({
        pageProps,
        payload:"CLEAR"
    })
  }, []);

  return (
    <div className="biodata_info">
      <StudentBiodataDetails pageProps={pageProps} />
    </div>
  );
};

const StudentBiodataWrap = (pageProps: any) => {
  return (
    <UndergraduatePortal
      title="My Personal Data"
      childComponent={<StudentBiodata pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  getUnderGradBioData: uGradPortal.UnderGradGetBioData,
  printUnderGradBioData: uGradPortal.UnderGradPrintBioData,
  
};
const mapStateToProps = (state: any) => ({
  UnderGradGetBioDataRequest: state.allUGradPortalReducers.UnderGradGetBioData,
  UnderGradPrintBioDataRequest: state.allUGradPortalReducers.UnderGradPrintBioData,
  
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentBiodataWrap);
